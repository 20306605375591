import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import "../App.css";

function MainPage() {
  const [seasonList, setSeasonList] = useState([]);

  let history = useHistory();

  useEffect(() => {
    Axios.get("https://bbdevapi.oje.guru/api/seasons").then((data) => {
      console.log(data.data);
      setSeasonList(data.data);
    });
  }, []);

  return (
    <div className="SeasonPage">
      <div className="SeasonsContainer">
        {seasonList.map((val, key) => {
          return (
            <div className="Season" key={key}>
              <h1 className="episode-title">Staffel {val.season_nr}</h1>
              <img
                // onClick={() => history.push(`/episodes/${val.season_id}`)}
                src={val.image}
                alt=""
              />
              <p>
                {val.plot.length > 300
                  ? val.plot.substring(0, 300) + " ..."
                  : val.plot}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MainPage;
