import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import "../App.css";

export default function Episode() {
  let { episodeId } = useParams();
  const [episode, setEpisode] = useState({});

  useEffect(() => {
    Axios.get(`https://bbdevapi.oje.guru/api/episodes/${episodeId}`).then(
      (data) => {
        console.log(data);
        setEpisode({
          episode_nr: data.data[0].episode_nr,
          title: data.data[0].title,
          plot: data.data[0].plot,
        });
      }
    );
  }, [episodeId]);

  return (
    <div className="container">
      <div className="individual">
        <h1 className="character-title">Episode: {episode.episode_nr}</h1>
        <h5 className="character-title">{episode.title}</h5>
        <p>{episode.plot}</p>
      </div>
    </div>
  );
}
