import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import "../App.css";

export default function Character() {
  let { characterId } = useParams();
  const [character, setCharacter] = useState({});

  useEffect(() => {
    Axios.get(`https://bbdevapi.oje.guru/api/chars/${characterId}`).then(
      (data) => {
        console.log(data);
        setCharacter({
          firstname: data.data[0].firstname,
          lastname: data.data[0].lastname,
          aliasname: data.data[0].aliasname,
          status_name: data.data[0].status_name,
          image: data.data[0].image,
          actor_firstname: data.data[0].actor_firstname,
          actor_lastname: data.data[0].actor_lastname,
          actor_birthday: data.data[0].actor_birthday,
          actor_age: data.data[0].actor_age,
          actor_image: data.data[0].actor_image,
          actor_url: data.data[0].actor_url,
        });
      }
    );
  }, [characterId]);

  return (
    <div className="container">
      <div className="individual">
        <h1 className="character-title">
          Charakter: {character.firstname} {character.lastname}
        </h1>
        <p>Spitzname: {character.aliasname}</p>
        <p>Serienstatus: {character.status_name}</p>
        <p>{character.lastname}</p>
        <img src={character.image} alt="" />
        <p style={{ textAlign: "center" }}>
          Foto © &nbsp;
          <a
            href="https://www.amc.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            AMC
          </a>{" "}
          und{" "}
          <a href={character.image} rel="noopener noreferrer" target="_blank">
            online-quelle
          </a>
        </p>
      </div>
      <div className="Actor individual">
        <h1 className="character-title">
          Schauspieler: {character.actor_firstname} {character.actor_lastname}
        </h1>
        <p>Geburtstag: {character.actor_birthday}</p>
        <p>Alter: {character.actor_age}</p>
        <p>
          <a
            href={character.actor_url}
            rel="noopener noreferrer"
            target="_blank"
          >
            Biografie
          </a>
        </p>
        <img src={character.actor_image} alt="" />
        <p style={{ textAlign: "center" }}>
          Foto © &nbsp;
          <a
            href={character.actor_image}
            rel="noopener noreferrer"
            target="_blank"
          >
            online-quelle
          </a>
        </p>
      </div>
    </div>
  );
}
