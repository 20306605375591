import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import "../App.css";

function CharactersPage() {
  const [characterList, setCharacterList] = useState([]);

  let history = useHistory();

  useEffect(() => {
    Axios.get("https://bbdevapi.oje.guru/api/chars").then((data) => {
      setCharacterList(data.data);
    });
  }, []);

  return (
    <div className="CharacterPage">
      <div className="CharactersContainer">
        {characterList.map((val, key) => {
          return (
            <div className="Character" key={key}>
              <p className="character-title">CharakterID: {val.character_id}</p>
              <p className="character-title">Vorname: {val.firstname}</p>
              <p className="character-title">Nachname: {val.lastname}</p>
              <p className="character-title">Spitznamen: {val.aliasname}</p>
              <img
                onClick={() => history.push(`/chars/${val.character_id}`)}
                src={val.image}
                alt=""
              />
              <p style={{ textAlign: "center", fontSize: "small" }}>
                Foto © &nbsp;
                <a
                  href="https://www.amc.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  AMC
                </a>{" "}
                und{" "}
                <a href={val.image} rel="noopener noreferrer" target="_blank">
                  online-quelle
                </a>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CharactersPage;
