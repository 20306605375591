import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import "../App.css";

function EpisodesPage() {
  const [episodeList, setEpisodeList] = useState([]);

  let history = useHistory();

  useEffect(() => {
    Axios.get("https://bbdevapi.oje.guru/api/episodes").then((data) => {
      setEpisodeList(data.data);
    });
  }, []);

  return (
    <div className="EpisodePage">
      <div className="EpisodesContainer">
        {episodeList.map((val, key) => {
          return (
            <div className="Episode" key={key}>
              <h1 className="episode-title">Episode {val.episode_nr}</h1>
              <h5 className="episode-title">{val.title}</h5>
              <img
                onClick={() => history.push(`/episodes/${val.episode_id}`)}
                src={val.image}
                alt=""
              />
              <p>
                {val.plot.length > 100
                  ? val.plot.substring(0, 100) + " ..."
                  : val.plot}
              </p>
            </div>
          );
        })}
      </div>
      <div className="stats"></div>
    </div>
  );
}

export default EpisodesPage;
