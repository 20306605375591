import { BrowserRouter as Router, Route } from "react-router-dom";
import Character from "./pages/Character";
import CharactersPage from "./pages/CharactersPage";
import Episode from "./pages/Episode";
import EpisodesPage from "./pages/EpisodesPage";
import MainPage from "./pages/MainPage";

const App = () => {
  return (
    <div>
      <div className="navbar">
        <div className="links">
          <a href="/">Start</a>
          <a href="/episodes">Episoden</a>
          <a href="/chars">Figuren</a>
        </div>
      </div>
      <Router>
        <Route path="/chars" exact render={(props) => <CharactersPage />} />
        <Route
          path="/chars/:characterId"
          exact
          render={(props) => <Character />}
        />
        <Route path="/episodes" exact render={(props) => <EpisodesPage />} />
        <Route
          path="/episodes/:episodeId"
          exact
          render={(props) => <Episode />}
        />
        <Route path="/" exact render={(props) => <MainPage />} />
      </Router>
    </div>
  );
};
export default App;
